<template>
  <v-container fluid class="pt-0">
    <v-row class="pt-0 mt-0">
      <v-col class="pt-0">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>
              <v-icon class="mr-2"> mdi-monitor-dashboard</v-icon>
              Commission Dashboard for this Quarter
            </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              :to="{ name: 'Add Commission' }"
            >
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Add Commission
            </v-btn>
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" v-bind="attrs" v-on="on" class="mr-4">
                  <v-icon class="mr-2"> mdi-calendar-month </v-icon>Year -
                  {{ selectedYear }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="year in last5Years"
                  :key="year"
                  @click="changeYear(year)"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" v-show="selectedYear == year">
                      mdi-circle-small
                    </v-icon>
                    {{ year }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" v-bind="attrs" v-on="on">
                  <v-icon class="mr-2"> mdi-calendar-star </v-icon>Quarter -
                  {{ selectedQuarter }}
                </v-btn>
              </template>
              <v-list>
                <template v-for="quarter in 4">
                  <v-list-item @click="changeQuarter(quarter)">
                    <v-list-item-title>
                    <v-icon class="mr-2" v-show="selectedQuarter == quarter">
                      mdi-circle-small
                    </v-icon>
                    Q{{ quarter }}
                  </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-alert color="orange" text prominent>
          <v-row align="center">
            <v-col class="grow">
              <v-icon class="mr-2 red--text"> mdi-alert-outline </v-icon>
              Hurry! There is only
              <span class="h4 red--text">{{ timeToCutOff }} </span> to enter
              your commission for this quarter!
            </v-col>
            <v-col class="shrink">
              <v-btn color="primary" :to="{ name: 'Add Commission' }">
                <v-icon class="mr-2"> mdi-plus </v-icon>
                Add Commission
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="text-center pb-0">
            <v-icon color="warning" class="mr-2">
              mdi-help-circle-outline </v-icon
            >To be approved
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 orange--text">
              £<trev-number-ticker
                :number="toBeApprovedSum"
                :duration="numberDuration"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="text-center pb-0">
            <v-icon color="success" class="mr-2">
              mdi-check-circle-outline </v-icon
            >Approved
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 green--text">
              £<trev-number-ticker
                :number="approvedSum"
                :duration="numberDuration"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="text-center pb-0">
            <v-icon color="danger" class="mr-2">
              mdi-help-circle-outline </v-icon
            >Queried
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 red--text">
              £<trev-number-ticker
                :number="deniedSum"
                :duration="numberDuration"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="text-center pb-0">
            <v-icon color="blue" class="mr-2"> mdi-calendar-today </v-icon
            >Finalised
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 blue--text">
              £<trev-number-ticker
                :number="totalSum"
                :duration="numberDuration"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <v-btn class="mr-3" color="info" :disabled="selectedCommissionStatus == 'Awaiting Approval'"
              @click="selectedCommissionStatus = 'Awaiting Approval'">

              Awaiting Approval
            </v-btn>
            <v-btn class="mr-3" color="info" :disabled="selectedCommissionStatus == 'Approved'"
            @click="selectedCommissionStatus = 'Approved'">
            Approved
          </v-btn>
          <v-btn class="mr-3" color="info" :disabled="selectedCommissionStatus == 'Denied'"
          @click="selectedCommissionStatus = 'Denied'">
          Denied
        </v-btn> -->
        <trev-cool-table
          ref="tblCommission"
          :addNewLink="{ name: 'Add Commission' }"
          :headers="headers"
          :courier="
            $courier.SystemUsers.withId($store.getters.userId).Commissions
          "
          :additionalQueryStrings="[
            { key: 'quarterNumber', value: selectedQuarter },
            { key: 'year', value: selectedYear },
            { key: 'statusFilter', value: selectedCommissionStatus },
          ]"
        >
          <template #title>
            Commissions -
            <span class="primary--text text--lighten-4">{{
              selectedCommissionStatus
            }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="selectedCommissionStatus = 'Awaiting Approval'"
                  :disabled="selectedCommissionStatus == 'Awaiting Approval'"
                >
                  <v-list-item-title>
                    <v-icon
                      class="mr-2"
                      v-show="selectedCommissionStatus == 'Awaiting Approval'"
                    >
                      mdi-circle-small
                    </v-icon>
                    Awaiting Approval
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="selectedCommissionStatus = 'Approved'"
                  :disabled="selectedCommissionStatus == 'Approved'"
                >
                  <v-list-item-title>
                    <v-icon
                      class="mr-2"
                      v-show="selectedCommissionStatus == 'Approved'"
                    >
                      mdi-circle-small
                    </v-icon>
                    Approved
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="selectedCommissionStatus = 'Queried'"
                  :disabled="selectedCommissionStatus == 'Queried'"
                >
                  <v-list-item-title>
                    <v-icon
                      class="mr-2"
                      v-show="selectedCommissionStatus == 'Queried'"
                    >
                      mdi-circle-small
                    </v-icon>
                    Queried
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #item.rate="{ item }">
            <v-chip color="success" small> {{ item.rate }}% </v-chip>
          </template>
          <template #item.invoiceNumber="{ item }">
            <v-chip label color="info" small v-if="item.invoiceId">
              {{ item.invoiceNumber }}
            </v-chip>
          </template>
          <template #item.poNumber="{ item }">
            <v-chip label small>
              {{ item.poNumber }}
            </v-chip>
          </template>
          <template #item.invoiceDate="{ item }">
            {{ new Date(item.invoiceDate).toLocaleDateString() }}
          </template>
          <template #item.commissionAmount="{ item }">
            <v-chip color="success" label small>
              £{{ generalUtilitiesFrontEnd.getCommissionFromObj(item) }}
            </v-chip>
          </template>
          <template #item.actions="{ item }">
            <v-btn
              small
              color="info"
              :to="{
                name: 'View Commission',
                params: { commissionId: item.id },
              }"
            >
              <v-icon class="mr-2"> mdi-magnify </v-icon>
              View
            </v-btn>
          </template>
        </trev-cool-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as GeneralUtilities from "../../../../utilities/GeneralUtilities";
import trevNumberTicker from "../../../../components/ui/trev-number-ticker.vue";
import TrevCoolTable from "../../../../components/tables/trev-cool-table.vue";
export default {
  components: { trevNumberTicker, TrevCoolTable },
  watch: {
    selectedCommissionStatus(newValue, oldValue) {
      this.$nextTick(() => {
        this.$refs.tblCommission.getData();
      });
    },
    selectedQuarter(newVal, oldVal) {
      this.$nextTick(() => {
        this.getCommissionForQuarter();

        this.$refs.tblCommission.getData();
      });
    },
    selectedYear(newVal, oldVal) {
      this.$nextTick(() => {
        this.getCommissionForQuarter();

        this.$refs.tblCommission.getData();
      });
    },
  },
  created() {
    this.generalUtilitiesFrontEnd = GeneralUtilities;
    if(this.$store.getters.getCommissionYear > 0){
      this.selectedYear = this.$store.getters.getCommissionYear;
    }
    else{
      this.selectedYear = new Date().getFullYear();
    }

    if(this.$store.getters.getCommissionQuarter > 0){
      this.selectedQuarter = this.$store.getters.getCommissionQuarter;
    }
    else{
      this.selectedQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    }

    this.getCommissionForQuarter();
  },
  computed: {
    last5Years() {
      var allYears = [];
      var currentDate = new Date();
      for (
        var i = currentDate.getFullYear() - 3;
        i <= currentDate.getFullYear();
        i++
      ) {
        allYears.push(i);
      }

      return allYears;
    },
    shouldShowWarning() {
      var stopDate = GeneralUtilities.getTimeUntilNextCommissionCutOff(
        this.selectedQuarter,
        this.selectedYear
      );
      var cutOff = GeneralUtilities.getDurationFromTwoDates(
        new Date(),
        stopDate
      );

      if (cutOff.months > 0) {
        return true;
      } else {
        return false;
      }
    },
    timeToCutOff() {
      var stopDate = GeneralUtilities.getTimeUntilNextCommissionCutOff(
        this.selectedQuarter,
        this.selectedYear
      );
      var cutOff = GeneralUtilities.getDurationFromTwoDates(
        new Date(),
        stopDate
      );

      if (cutOff.months > 0) {
        return cutOff.months + " months";
      }

      if (cutOff.days > 0) {
        return cutOff.days + " days";
      }

      if (cutOff.hours > 0) {
        return cutOff.hours + " hours";
      }

      if (cutOff.minutes > 0) {
        return cutOff.minutes + " minutes";
      }
    },
  },
  methods: {
    changeYear(yearNumber) {
      this.selectedYear = yearNumber;
      this.$store.commit('setCommissionYear', yearNumber);
    },
    changeQuarter(quarterNumber) {
      this.selectedQuarter = quarterNumber;
      this.$store.commit('setCommissionQuarter', quarterNumber);
    },
    async getCommissionForQuarter() {
      //TODO split these requests into their own ones and put in parralel to optimise

      var commissions = await this.$courier.SystemUsers.withId(
        this.$store.getters.userId
      ).Commissions.getData(1, 999999, "", null, null, [
        { key: "year", value: this.selectedYear },
        { key: "quarterNumber", value: this.selectedQuarter },
      ]);

      this.toBeApprovedSum = this.getCommissionSumForStatus(
        "Awaiting Approval",
        commissions.payload
      );
      this.approvedSum = this.getCommissionSumForStatus(
        "Approved",
        commissions.payload
      );
      this.deniedSum = this.getCommissionSumForStatus(
        "Queried",
        commissions.payload
      );
      this.totalSum = this.getCommissionSumForStatus(
        "Finalised",
        commissions.payload
      );
    },
    getCommissionSumForStatus(statusName, commissionArray) {
      var total = 0;

      for (const commission of commissionArray.filter(
        (x) => x.status === statusName
      )) {
        total += GeneralUtilities.getCommissionFromObj(commission);
      }

      return total;
    },
  },
  data() {
    return {
      numberDuration: 1,
      generalUtilitiesFrontEnd: {},
      totalCountTimeOutId: "",
      toBeApprovedSum: 0,
      approvedSum: 0,
      deniedSum: 0,
      totalSum: 0,
      commissions: [],
      selectedYear: 0,
      selectedQuarter: 0,
      selectedCommissionStatus: "Awaiting Approval",
      headers: [
        {
          text: "Invoice Number",
          value: "invoiceNumber",
        },
        {
          text: "PO Number",
          value: "poNumber",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Invoice Date",
          value: "invoiceDate",
        },
        {
          text: "Rate",
          value: "rate",
        },
        {
          text: "Commission Amount",
          value: "commissionAmount",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>
